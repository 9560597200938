import {field} from "../../../core/models/fields/Field";
import {Model} from "../../../core/models/Model";
import {APIResource} from '../../../core/models/APIResource';

export class Font extends Model {
    static override uri:string = '/custom/api/v1/font/:id/';
    static override objects: APIResource<Font>;
    static LoadedFonts = [];

    @field()
    family:string;

    @field()
    family_web:string;

    @field()
    name:string;

    @field()
    font: string;

    @field()
    font_preview: string;

    @field()
    preview: string;

    @field()
    default: boolean;

    fontFamily() {
        return this.family_web ? this.family_web : this.family + ' Preview';
    }

    fontPreview() {
        if (this.font_preview) {
            return this.font_preview;
        }
        return this.font;
    }

    // Load the font into the browser
    async load() {
        if (Font.LoadedFonts.indexOf(this.id) != -1) {
            return;
        }

        // Check if its already loaded
        for (const font of document.fonts) {
            if (font.family == this.family) {
                return;
            }
        }

        const font_face = new FontFace(this.fontFamily(), `url(${this.fontPreview()})`);
        document.fonts.add(font_face);
        await font_face.load();
    }
}
