import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ToManyURIField, TToManyKeyURI} from '../../../core/models/fields/ToManyURIField';
import {Model} from '../../../core/models/Model';
import {ClipartCategory} from './ClipartCategory';
import {APIResource} from '../../../core/models/APIResource';
import {Collection} from '../../../core/models/Collection';
import {ClipartRegion} from './ClipartRegion';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';

export enum EClipartColorChoiceEnum {
    SINGLE_COLOR,
    MULTI_COLOR
}

export class Clipart extends Model {
    static override uri: string = '/custom/api/v1/clipart/:id/';
    static override objects: APIResource<Clipart>;

    @field()
    clipart: string;

    @field()
    colors: EClipartColorChoiceEnum;

    @field()
    height: number;

    @field()
    keywords: string;

    @field()
    keywords_stemmed: string;

    @field()
    name: string;

    @field()
    preview: string;

    @field()
    problem: string;

    @field()
    product_number: string;

    @field(ToManyField, {
        model: 'ClipartRegion'
    })
    regions: Collection<ClipartRegion>;

    @field()
    searchable: boolean;

    @field()
    slug: string;

    @field()
    template_clipart: boolean;

    @field()
    width: number;

    @field(ForeignKeyURIField, {
        model: 'ClipartCategory'
    })
    category: TForeignKeyURI<ClipartCategory>;

    @field(ToManyURIField, {
        model: 'ClipartCategory'
    })
    categories: TToManyKeyURI<ClipartCategory>;

    @field()
    popularity: number;

    @field()
    replaced_in_processed: boolean;
}
