import VueApp from '../../../core/adapters/VueApp';
import {data} from '../../../core/adapters/VueComponent';
import {Services} from '../../../core/services/Services';

export class ProductImageApp extends VueApp {

    @data()
    image_display: any;

    @data()
    show_preview_tool: boolean;

    constructor() {
        super();

        Services.waitOn('product-service-loaded').then(() => {
            let service: any = Services.get('ProductService');

            if (service.show_preview_tool) {
                this.show_preview_tool = service.show_preview_tool;
            }
            else {
                service.bind('show-preview-tool', () => {
                    this.show_preview_tool = service.show_preview_tool;
                });
            }
        });
    }
}