import {field} from '../../../core/models/fields/Field';
import {Model} from '../../../core/models/Model';
import {Services} from '../../../core/services/Services';
import NamedRouteService from '../../../core/services/NamedRouteService';


export enum DisplayChoices {
    DISPLAY_PRODUCTS = 0,
    DISPLAY_CATEGORIES = 1,
    DISPLAY_PRODUCTS_WITH_FILTERS = 2,
    DISPLAY_CATEGORIES_WITH_PRODUCTS = 3,
    DISPLAY_HTML = 4,
    DISPLAY_BANNER = 5,
    DISPLAY_STICKERS = 6,
    DISPLAY_USES = 7,
    DISPLAY_NEW_PRODUCTS = 8,
    DISPLAY_POPULAR_PRODUCTS = 9,
    DISPLAY_BUILDER = 10
}

export enum OrderBy {
    ORDER = 1,
    POPULARITY = 2,
    ALPHABETICAL = 3
}

export class Category extends Model {
    static override uri: string = '/products/api/v1/category/:id/';

    @field()
    name: string;

    @field()
    name_path: string;

    @field()
    name_plural: string;

    @field()
    name_verbose: string;

    @field()
    slug: string;

    @field()
    path: string;

    @field()
    parent: string;

    @field()
    short_description: string;

    get absoluteUrl(): string {
        const $NamedRouteService = Services.get<NamedRouteService>('$NamedRouteService');
        return $NamedRouteService.reverse('category:detail', {
            categoryPath: this.path
        });
    }
}

