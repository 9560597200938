import {field} from '../../../core/models/fields/Field';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {ToManyURIField} from '../../../core/models/fields/ToManyURIField';
import {ProductAbstract} from './abstract/ProductAbstract';
import {TemplateSizeDisplayEnum} from './abstract/ProductSizeAbstract';
import {Color} from './Color';
import {TemplateShape} from './TemplateShape';
import {ToolTypeEnum} from './enums';
import {TemplateOptionSet} from './TemplateOptionSet';
import NamedRouteService from '../../../core/services/NamedRouteService';
import {Services} from '../../../core/services/Services';
import {TemplateSize} from './TemplateSize';
import {Collection} from '../../../core/models/Collection';
import {TemplateText} from './TemplateText';
import {TemplateMarketplace} from './TemplateMarketplace';
import {TemplateClipart} from './TemplateClipart';
import {APIResource} from '../../../core/models/APIResource';
import {TemplateSide} from './TemplateSide';


export enum MaterialDisplay {
    STANDARD_MATERIAL_LIST_DISPLAY = 1,
    MATERIAL_ADHESIVE_DISPLAY = 2
}


export class Template extends ProductAbstract {
    static override uri: string = '/custom/api/v1/template/:id/';
    static override objects: APIResource<Template>;

    @field()
    allow_custom_size: boolean;

    @field()
    background_alignment_horizontal: number;

    @field()
    vectorize_price_override: number;

    @field()
    canonical_url: string;

    @field(ToManyURIField, {
        model: 'TemplateClipart'
    })
    clipart_elements: Collection<TemplateClipart>;

    @field(ToManyURIField, {
        model: 'TemplateShape'
    })
    template_shapes: Collection<TemplateShape>;

    @field()
    details: string;

    @field()
    canvas_height: number;

    @field()
    canvas_width: number;

    @field()
    option_vectorize: boolean;

    @field()
    option_border: boolean;

    @field()
    popup_clipart: boolean;

    @field()
    popup_upload: boolean;

    @field()
    popup_qr_setup: boolean;

    @field()
    rating: string;

    @field()
    tool_background: string;

    @field()
    size_display: TemplateSizeDisplayEnum;

    @field(ToManyField, {
        model: 'TemplateSize'
    })
    sizes: Collection<TemplateSize>;

    @field(ToManyURIField, {
        model: 'TemplateText'
    })
    text_elements: Collection<TemplateText>;

    @field(ToManyURIField, {
        model: 'TemplateMarketplace'
    })
    marketplace_elements: Collection<TemplateMarketplace>;

    @field()
    tool_type: ToolTypeEnum;

    @field(ForeignKeyField, {
        model: 'Color'
    })
    cut_out_color: Color;

    @field()
    defined_quantity: boolean;

    @field()
    hero_image: string;

    @field()
    video: any;

    @field()
    disable_design: boolean;

    @field()
    disable_upload: boolean;

    @field()
    disable_qr: boolean;

    @field(ForeignKeyField, {
        model: 'TemplateOptionSet',
        readOnly: true
    })
    licensing_option: TemplateOptionSet;

    @field(ForeignKeyField, {
        model: 'TemplateOptionSet',
        readOnly: true
    })
    multi_side_option: TemplateOptionSet;

    @field()
    show_material_color: boolean;

    @field()
    auto_background_removal: boolean;

    @field()
    background_tab_default: boolean

    @field()
    hide_size: boolean;

    @field()
    hide_material: boolean;

    @field()
    disable_raster_warnings: boolean;

    @field()
    show_variable_data: boolean;

    @field()
    material_display: MaterialDisplay;

    @field()
    overlay_sides: boolean;

    @field(ForeignKeyField, {
        model: 'TemplateSide'
    })
    background_shape_side: TemplateSide;

    @field()
    disable_remove_background: boolean;

    @field()
    offset_path: boolean;

    @field()
    offset_path_uses_material_color: boolean;

    @field()
    default_vectorization_price: number;

    @field()
    currency: string;

    @field(ToManyField, {
        model: 'TemplateOptionSet'
    })
    options: Collection<TemplateOptionSet>;

    @field()
    design_only: boolean;

    get hasOptions() {
        let o: boolean = false;

        if (this.option_border)
            o = true;

        if (this.option_laminate)
            o = true;

        if (this.option_vectorize)
            o = true;

        if (this.options && this.options.length > 0)
            o = true;

        return o;
    }

    override get url() {
        if (!this.category || !this.category.path)
            return '';

        if (this.tool_type == ToolTypeEnum.LETTERING)
            return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:lettering-new', {
                categoryPath: this.category.path,
                productSlug: this.slug
            });

        return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:setup', {
            categoryPath: this.category.path,
            productSlug: this.slug
        });
    }

    get tool_url() {
        return Services.get<NamedRouteService>('$NamedRouteService').reverse('svgcustom:personalize', {
            categoryPath: this.category.path,
            productSlug: this.slug
        });
    }

    get showWholesalePrices(): boolean {
        return [
            ToolTypeEnum.EASY,
            ToolTypeEnum.ADVANCED,
            ToolTypeEnum.UPLOAD,
            ToolTypeEnum.LETTERING
        ].indexOf(this.tool_type) > -1;
    }
}
