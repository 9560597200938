import {field} from '../../../../core/models/fields/Field';
import {FloatField} from '../../../../core/models/fields/FloatField';
import {PriceField} from '../../../../core/models/fields/PriceField';
import {IModel} from '../../../../core/models/IModel';
import {Model} from '../../../../core/models/Model';
import {Price} from '../../../../core/utils/Price';
import {Services} from '../../../../core/services/Services';
import UserProfileService from '../../../userprofile/services/UserProfileService';
import Money from '../../../../core/utils/Money';
import {MoneyField} from '../../../../core/models/fields/MoneyField';

export interface IProductSize extends IModel {
    label?: string;
    name?: string;
    value?: number;
    width?: number;
    height?: number;
    default?: boolean;
    price?: Price;
}

export enum TemplateSizeDisplayEnum {
    DISPLAY_SIZE_AND_AREA = 0,
    DISPLAY_DIMENSIONS = 1,
    DISPLAY_NAME = 2,
    DISPLAY_NAME_RADIO = 3
}

export abstract class ProductSizeAbstract extends Model implements IProductSize {
    protected displayOption: TemplateSizeDisplayEnum = TemplateSizeDisplayEnum.DISPLAY_SIZE_AND_AREA;

    @field()
    public name: string;

    @field(FloatField, {
        toFixed: 3,
        required: true
    })
    public width: number;

    @field(FloatField, {
        toFixed: 3,
        required: true
    })
    public height: number;

    @field()
    public default: boolean;

    @field(PriceField)
    public price: Price;

    @field()
    currency: string;

    /*
    @field(MoneyField, {
        amount_field: 'price',
        currency_field: 'currency'
    })
    public money: Money;
     */

    setLabelDisplayOption(displayOption: TemplateSizeDisplayEnum) {
        this.displayOption = displayOption;
    }

    get label() {
        let ups = Services.get<UserProfileService>('UserProfileService');

        switch (this.displayOption) {
            case TemplateSizeDisplayEnum.DISPLAY_SIZE_AND_AREA:
                return `${ups.convertSizeFromInches(this.width)}" x ${ups.convertSizeFromInches(this.height)} — ${this.name}`;
            case TemplateSizeDisplayEnum.DISPLAY_DIMENSIONS:
                return `${ups.convertSizeFromInches(this.width)}" x ${ups.convertSizeFromInches(this.height)}"`;
            case TemplateSizeDisplayEnum.DISPLAY_NAME:
                return this.name;
            case TemplateSizeDisplayEnum.DISPLAY_NAME_RADIO:
                return `${this.name} — ${ups.convertSizeFromInches(this.width)}" x ${ups.convertSizeFromInches(this.height)}`
        }
    }
}
