import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {ForeignKeyURIField, TForeignKeyURI} from '../../../core/models/fields/ForeignKeyURIField';
import {MarketplaceProduct} from './MarketplaceProduct';
import {APIResource} from '../../../core/models/APIResource';

export class MarketplaceProductImage extends Model {
    public static override uri: string = '/marketplace/api/v1/product-image/:id/';
    static override objects: APIResource<MarketplaceProductImage>;

    @field(ForeignKeyURIField, {
        model: 'MarketplaceProduct'
    })
    public product: TForeignKeyURI<MarketplaceProduct>;

    @field()
    public name: string;

    @field()
    public order: number;

    @field()
    image: string;
}
