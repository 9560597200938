import {Services} from '../services/Services';
import {yieldThread} from './utils';

export abstract class IntegrationExtra {
    constructor() {
        Services.waitOn('loaded-core').then(() => {
            this.setupGlobalModules();
            this.setupModels();
            this.setupServices();
            this.configureServices();
            yieldThread().then(() => {
                this.setupApps();
            })
        })
    }

    configureServices(): void {};
    setupGlobalModules(): void {};

    abstract setupModels(): void;
    abstract setupServices(): void;
    abstract setupApps(): void;
}