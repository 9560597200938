import registerCategoryModels from '../../ts/apps/category/models/init';
import registerCustomModels from '../../ts/apps/svgcustom/models/init';
import registerPackagingModels from '../../ts/apps/packaging/models/init';
import {IntegrationExtra} from '../../ts/core/utils/IntegrationExtra';
import {ProductImageApp} from '../../ts/apps/svgcustom/apps/ProductImageApp';
import registerComponents from '../../ts/core/components/init';
import registerMarketplaceModels from '../../ts/apps/marketplace/models/custom.init';

export default class CustomCommonIntegration extends IntegrationExtra {
    setupApps(): void {
        ProductImageApp.mount('#product-image-app', (app) => {
            registerComponents(app);
        });
    }

    setupModels(): void {
        registerCategoryModels();
        registerCustomModels();
        registerPackagingModels();
        registerMarketplaceModels();
    }

    setupServices(): void {

    }
}