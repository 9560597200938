import {field} from '../../../core/models/fields/Field';
import MessageList from '../../../core/utils/MessageList';
import {CustomLamination} from './enums';
import {MaxSizeAbstract} from './abstract/MaxSizeAbstract';
import {APIResource} from '../../../core/models/APIResource';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Collection} from '../../../core/models/Collection';
import {Color} from './Color';
import {FloatField} from '../../../core/models/fields/FloatField';
import {ColorMap} from './ColorMap';

export class Material extends MaxSizeAbstract {
    static override uri: string = '/custom/api/v1/template-material/:id/';
    static override objects: APIResource<Material>;

    @field()
    name: string;

    @field()
    material_name: string;

    @field()
    adhesive: string;

    @field()
    has_own_colors: boolean;

    @field()
    can_laminate: boolean;

    @field()
    description: string;

    @field()
    description_html: string;

    @field()
    order: number;

    @field()
    price_table: any;

    @field()
    reversible: boolean;

    @field()
    material_color: string;

    @field(FloatField)
    variable_data_charge: number;

    @field()
    allowed_quantities: any;

    @field()
    default_lamination: CustomLamination;

    @field()
    disable_promotional_text: boolean;

    @field()
    backordered: boolean;

    @field(ToManyField, {
        model: 'Color'
    })
    design_colors: Collection<Color>;

    @field(ToManyField, {
        model: 'Color'
    })
    material_colors: Collection<Color>;

    @field(ToManyField, {
        model: 'ColorMap'
    })
    color_maps: Collection<ColorMap>;

    @field()
    clear: boolean;

    @field()
    instructions_label: string;

    quantityIsValid(quantity): boolean {
        if (!this.allowed_quantities || !quantity) {
            return true;
        }
        let quantities_array = this.allowed_quantities.split(',');
        if (quantities_array.includes(quantity.toString()) || parseInt(quantity) % parseInt(quantities_array[0]) == 0) {
            return true;
        }

        if (!this._errors) {
            this._errors = new MessageList();
        }

        this._errors.remove('quantity');
        this._errors.add('quantity', 'Please enter multiples of ' + quantities_array[0] + '.');
        return false;
    }
}
