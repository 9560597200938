import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {MarketplaceArtist} from './MarketplaceArtist';
import {MarketplaceArtwork} from './MarketplaceArtwork';
import {MarketplaceCompany} from './MarketplaceCompany';
import {MarketplaceProductImage} from './MarketplaceProductImage';


export default function registerModels():  void {
    const models: TModelDefinitionList = {
            MarketplaceArtist: MarketplaceArtist,
            MarketplaceArtwork: MarketplaceArtwork,
            MarketplaceCompany: MarketplaceCompany,
            MarketplaceProductImage: MarketplaceProductImage,
    };

    Model.registerModels(models);
}
