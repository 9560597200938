import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {Material} from './Material';
import {ClipartCategory} from './ClipartCategory';
import {CustomOption} from './CustomOption';
import {TemplateOption} from './TemplateOption';
import {TemplateOptionSet} from './TemplateOptionSet';
import {ClipartProductBackground} from './ClipartProductBackground';
import {TemplateOptionTag} from './TemplateOptionTag';
import {Color} from './Color';
import {BackgroundImage} from './BackgroundImage';
import {Clipart} from './Clipart';
import {ClipartCategoryGraphic} from './ClipartCategoryGraphic';
import {ClipartProduct} from './ClipartProduct';
import {ClipartProductCustomizationGroup} from './ClipartProductCustomizationGroup';
import {ClipartRegion} from './ClipartRegion';
import {ConfiguredClipartProduct} from './ConfiguredClipartProduct';
import {ConfiguredClipartProductRegion} from './ConfiguredClipartProductRegion';
import {ConfiguredDragDropBuyProduct} from './ConfiguredDragDropBuyProduct';
import {ConfiguredDragDropBuyProductClipart} from './ConfiguredDragDropBuyProductClipart';
import {ConfiguredDragDropBuyProductClipartRegion} from './ConfiguredDragDropBuyProductClipartRegion';
import {Custom} from './Custom';
import CustomAutosave from './CustomAutosave';
import {CustomClipart} from './CustomClipart';
import {CustomClipartRegion} from './CustomClipartRegion';
import {CustomClipMask} from './CustomClipMask';
import {CustomMarketplace} from './CustomMarketplace';
import {CustomQRCode} from './CustomQRCode';
import {TemplateShape} from './TemplateShape';
import {CustomText} from './CustomText';
import {CustomUserFile} from './CustomUserFile';
import {DragDropBuyProduct} from './DragDropBuyProduct';
import {DragDropBuyProductClipart} from './DragDropBuyProductClipart';
import {DragDropBuyProductSize} from './DragDropBuyProductSize';
import {Font} from './Font';
import {QRCode} from './QRCode';
import {RecommendedProduct} from './RecommendedProduct';
import {SimpleClipartProduct} from './SimpleClipartProduct';
import {SimpleTemplate} from './SimpleTemplate';
import {StockProductSize} from './StockProductSize';
import {Template} from './Template';
import {TemplateCategory} from './TemplateCategory';
import {TemplateClipart} from './TemplateClipart';
import {TemplateClipartRegion} from './TemplateClipartRegion';
import {TemplateMarketplace} from './TemplateMarketplace';
import {TemplateSide} from './TemplateSide';
import {TemplateSize} from './TemplateSize';
import {TemplateTab} from './TemplateTab';
import {TemplateText} from './TemplateText';
import {TextPath} from './TextPath';
import {UserFile} from './UserFile';
import {DummyClipart, DummyText} from './ConfiguredDragDropBuyProductClipart';
import {RecommendedProductGroup} from './RecommendedProductGroup';
import {Tag} from './Tag';
import {ColorMap} from './ColorMap';
import {CustomShape} from './CustomShape';
import {ConfiguredClipartOption} from './ConfiguredClipartOption';

export default function registerModels():  void {
    const models: TModelDefinitionList = {
        BackgroundImage: BackgroundImage,
        Clipart: Clipart,
        ClipartCategory: ClipartCategory,
        ClipartCategoryGraphic: ClipartCategoryGraphic,
        ClipartProduct: ClipartProduct,
        ClipartProductBackground: ClipartProductBackground,
        ClipartProductCustomizationGroup: ClipartProductCustomizationGroup,
        ClipartRegion: ClipartRegion,
        Color: Color,
        ConfiguredClipartProduct: ConfiguredClipartProduct,
        ConfiguredClipartProductRegion: ConfiguredClipartProductRegion,
        ConfiguredDragDropBuyProduct: ConfiguredDragDropBuyProduct,
        ConfiguredDragDropBuyProductClipart: ConfiguredDragDropBuyProductClipart,
        ConfiguredDragDropBuyProductClipartRegion: ConfiguredDragDropBuyProductClipartRegion,
        Custom: Custom,
        CustomAutosave: CustomAutosave,
        CustomClipart: CustomClipart,
        CustomClipartRegion: CustomClipartRegion,
        CustomClipMask: CustomClipMask,
        CustomMarketplace: CustomMarketplace,
        CustomOption: CustomOption,
        CustomQRCode: CustomQRCode,
        CustomShape: CustomShape,
        TemplateShape: TemplateShape,
        CustomText: CustomText,
        CustomUserFile: CustomUserFile,
        DragDropBuyProduct: DragDropBuyProduct,
        DragDropBuyProductClipart: DragDropBuyProductClipart,
        DragDropBuyProductSize: DragDropBuyProductSize,
        Font: Font,
        Material: Material,
        QRCode: QRCode,
        RecommendedProduct: RecommendedProduct,
        SimpleClipartProduct: SimpleClipartProduct,
        SimpleTemplate: SimpleTemplate,
        StockProductSize: StockProductSize,
        Template: Template,
        TemplateCategory: TemplateCategory,
        TemplateClipart: TemplateClipart,
        TemplateClipartRegion: TemplateClipartRegion,
        TemplateMarketplace: TemplateMarketplace,
        TemplateOption: TemplateOption,
        TemplateOptionSet: TemplateOptionSet,
        TemplateOptionTag: TemplateOptionTag,
        TemplateSide: TemplateSide,
        TemplateSize: TemplateSize,
        TemplateTab: TemplateTab,
        TemplateText: TemplateText,
        TextPath: TextPath,
        UserFile: UserFile,
        DummyText: DummyText,
        DummyClipart: DummyClipart,
        RecommendedProductGroup: RecommendedProductGroup,
        Tag: Tag,
        ColorMap: ColorMap,
        ConfiguredClipartOption: ConfiguredClipartOption
    };

    Model.registerModels(models);
}