import {Model} from '../../../../core/models/Model';
import MessageList from '../../../../core/utils/MessageList';
import {field} from '../../../../core/models/fields/Field';
import {Services} from '../../../../core/services/Services';
import UserProfileService from '../../../userprofile/services/UserProfileService';

export class MaxSizeAbstract extends Model {
    @field()
    max_height: number;

    @field()
    max_width: number;

    @field()
    max_is_interchangeable: boolean;

    @field()
    minimum_width: number;

    @field()
    minimum_height: number;

    sizeIsRestricted() {
        return !!(this.max_width || this.max_height);
    }

    sizeIsValid(width, height, dont_error?: boolean): boolean {
        let ups = Services.get<UserProfileService>('UserProfileService');

        const invalid: any[] = [null, undefined, ''];
        if (invalid.indexOf(width) > -1 || invalid.indexOf(height) > -1 || isNaN(width) || isNaN(height) || height <= 0 || width <= 0) {
            return false;
        }

        const interchangeable = this.max_is_interchangeable;
        const max_width = this.max_width ? Number(this.max_width) : Number.MAX_VALUE;
        const max_height = this.max_height ? Number(this.max_height) : Number.MAX_VALUE;
        const minimum_width = this.minimum_width ? Number(this.minimum_width) : this.minimum_width;
        const minimum_height = this.minimum_height ? Number(this.minimum_height) : this.minimum_height;

        if (!this._errors) {
            this._errors = new MessageList();
        }

        if (minimum_width > width || minimum_height > height) {
            this._errors.add('size', `The size is smaller than the minimum of ${ups.formatMeasurement(this.minimum_width)} x ${ups.formatMeasurement(this.minimum_height)} allowed for this material.`);

            return false;
        }

        if (max_width == null && max_height == null) {
            if (this._errors) {
                this._errors.remove('size');
            }

            return true;
        }

        const check = (width <= max_width && height <= max_height) ||
            (interchangeable && (width <= max_height && height <= max_width));

        if (!check) {
            let message: string = null;
            if (max_width !== Number.MAX_VALUE && max_height !== Number.MAX_VALUE) {
                message = `The dimensions exceed the max size of ${ups.formatMeasurement(max_width)} by ${ups.formatMeasurement(max_height)}`;
            }
            else if (max_width !== Number.MAX_VALUE) {
                message = 'Only one dimension can exceed ' + ups.formatMeasurement(max_width);
            }
            else {
                message = 'Only one dimension can exceed ' + ups.formatMeasurement(max_height);
            }

            if (this._errors == null) {
                this._errors = new MessageList();
            }

            if (!dont_error) {
                this._errors.remove('size');
                this._errors.add('size', message);
            }

            return false;
        }
        else {
            if (this._errors) {
                this._errors.remove('size');
            }
        }

        return true;
    }
}
